// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-post-view-js": () => import("./../src/pages/post-view.js" /* webpackChunkName: "component---src-pages-post-view-js" */),
  "component---src-pages-recruit-js": () => import("./../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-service-js": () => import("./../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-technology-js": () => import("./../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-vision-js": () => import("./../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

